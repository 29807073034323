.flex-basis-5 {
  flex-basis: 5%;
}
.flex-basis-10 {
  flex-basis: 10%;
}
.flex-basis-15 {
  flex-basis: 15%;
}
.flex-basis-20 {
  flex-basis: 20%;
}
.flex-basis-25 {
  flex-basis: 25%;
}
