/* Styles for the modal */
.header-modal_select_option_code {
    color: 'white';
    background: linear-gradient(180deg, #282556 0%, #242244 100%);
}

.modal-body_profile_avatar {
    border-radius: 100px;
}

.modal-body_user_name {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    text-align: center;
}

.modal-body_group_contact_way {
    margin-left: 10px;
    font-size: 12px;
    font-weight: bolder;

}

.modal-body_subtitle {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    /* display: flex; */
    align-items: center;
    text-align: center;
    /* Text color */
    color: #393E48;
}

span:hover {
    text-decoration: underline;
}

.time-text {
    font-size: 25px;
    color: #FFFFFF;
}

.date-text {
    color: #FFFFFF;
}

.icons-modal {
    /* position: absolute; */
    max-width: 15%;
    max-height: 15%;
    /* left: 843px; */
    /* top: 522px; */
}

.modal-body_methods_group {
    margin-bottom: 10px;
}