.date-filter-input {
  border: none;
  font-size: 0.6875rem;
  color: var(--gray-low-risk);
}
.date-filter-input:hover {
  cursor: pointer;
}

.date-filter-input ~ img:hover {
  cursor: pointer;
}
