.sticky-note-container {
  position: relative;
  padding: 1rem 1rem 2rem 1rem;
  background: var(--light-gray);
  overflow: auto;
}

.sticky-note-container::after {
  background: linear-gradient(-45deg, var(--white) 0.5rem, transparent 0),
    linear-gradient(45deg, var(--white) 0.5rem, transparent 0);
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 1rem 1rem;
  content: ' ';
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 1.5rem;
}

.notification-date {
  color: var(--gray-low-risk);
  font-weight: 400;
}
